<template>
  <!-- 个人中心-资料、钱包 -->
  <div class="container">
    <a-page-header :title="title" :subTitle="subTitle" />
    <div class="main-content">
      <div class="body">
        <div class="left">
          <div
            class="item"
            :class="{ active: tabKey === 0 }"
            @click="tabKeyClick(0)"
          >
            基础信息
          </div>
          <div
            class="item"
            :class="{ active: tabKey === 1 }"
            @click="tabKeyClick(1)"
            v-if="userType !== 'Madmin'"
          >
            钱包（流水）
          </div>
          <div
            class="item"
            :class="{ active: tabKey === 2 }"
            @click="tabKeyClick(2)"
            v-if="userType === 'community'"
          >
            邀请渠道商
          </div>
          <div
            class="item"
            :class="{ active: tabKey === 3 }"
            @click="tabKeyClick(3)"
            v-if="userType === 'community'"
          >
            渠道商
          </div>
          <!-- <div
            class="item"
            :class="{ active: tabKey === 4 }"
            @click="tabKey = 4"
            v-if="userType === 'community'"
          >
            旗下服务师傅
          </div> -->
        </div>
        <div class="right">
          <div class="item" v-if="tabKey === 0">
            <div class="content-item" v-if="info" style="position: relative">
              <div class="content-item-header">
                <div class="title">基础信息</div>
              </div>
              <div class="content-sub-title">账号</div>

              <div class="ul">
                <div class="li">
                  <span class="label">登录账号：</span>
                  <span>{{ info.systemNum }}</span>
                </div>
                <div class="li">
                  <span class="label">账号类型：</span>
                  <span>{{ info.accountType }}</span>
                </div>
              </div>
              <div class="content-sub-title">账号持有人</div>
              <div class="ul">
                <div class="li">
                  <span class="label">用户角色：</span>
                  <span>{{ info.role }}</span>
                </div>
                <div class="li">
                  <span class="label">绑定微信：</span>
                  <span>{{ info.nickname }}</span>
                </div>
                <div class="li address">
                  <span class="label">账号使用人：</span>
                  <span>{{ info.username }}</span>
                </div>
              </div>
              <div class="shareCode">
                <a-button @click="modalVisibleClick()">分享有奖</a-button>
                <a-button @click="switchClick()" style="margin-left: 5px"
                  >换绑账号</a-button
                >
              </div>
            </div>
          </div>

          <div class="item" v-if="tabKey === 1">
            <div class="title">钱包/流水</div>

            <div class="title-2">
              <div class="price">
                <span class="label">钱包余额(元):</span>
                <a-statistic class="a-statistic" :value="balance" />
              </div>
              <div class="btns">
                <a-button>
                  <router-link to="/withdrawalHistory"> 提现记录 </router-link>
                </a-button>
                <a-button type="primary" class="btn-2" @click="onWithdrawOpen">
                  余额提现
                </a-button>
              </div>
            </div>

            <div class="title-3">流水记录</div>
            <a-table
              :columns="columns"
              :data-source="dataSource"
              :loading="loading"
              :pagination="pagination"
              :scroll="{ x: 910 }"
              :row-key="(record) => record.id"
              @change="handlePaginationChange"
            >
              <template slot="price" slot-scope="record">
                <a-statistic
                  class="a-statistic"
                  prefix="￥"
                  :value="record.price"
                />
              </template>
            </a-table>
          </div>
          <div class="item" v-if="tabKey === 2">
            <a-tabs
              :default-active-key="tabIndex"
              :animated="false"
              @change="callback"
            >
              <a-tab-pane
                v-for="(item, index) in tabs"
                :key="index"
                :tab="item.name"
              >
                <div v-if="tabIndex == 0">
                  <div class="main-content-header" style="margin-bottom: 10px">
                    <div class="item">
                      <div class="title">
                        <span class="title-1"></span>
                      </div>
                    </div>
                    <div class="item">
                      <a-button
                        type="primary"
                        @click="handleAddAnnualAssessmentLine"
                        >新增
                      </a-button>
                      <a-button type="primary" @click="handleAddAn">
                        确定
                      </a-button>
                    </div>
                  </div>
                  <a-table
                    :columns="colum"
                    :data-source="AnnualAssessmentData"
                    :pagination="false"
                    :row-key="(record) => record.id"
                  >
                    <template slot="name" slot-scope="scope">
                      <a-input v-model="scope.name" />
                    </template>
                    <template slot="mobile" slot-scope="scope">
                      <a-input v-model="scope.mobile" />
                    </template>
                    <template slot="desc" slot-scope="scope">
                      <a-input v-model="scope.desc" />
                    </template>
                    <template slot="rate" slot-scope="scope">
                      <!-- :parser='value =>/^\d+$/.test(value)?value:1' -->
                      <!-- <a-input
                    placeholder="请输入分配业务员比例"
                    v-model="formTion.spreadRate" -->
                      <!-- /> -->
                      <a-input
                        placeholder="请输入0%到100%的比例"
                        v-model="scope.rate"
                      />
                    </template>
                    <template slot-scope="text, record, index" slot="action">
                      <!-- <a-button
                        type="link"
                        @click="
                          handlesaveAnnualAssessment(scope, scope.row)
                        "
                        >保存
                      </a-button> -->
                      <!-- <a-button
                        v-if="AnnualAssessmentData.length == index + 1"
                        type="link"
                        @click="handleAddAnnualAssessmentLine"
                        >新增
                      </a-button> -->
                      <a-button
                        type="link"
                        @click="() => handleDeleteAnnualAssessment(index)"
                        >删除
                      </a-button>
                    </template>
                  </a-table>
                  <!-- <div>
                    <a-button @click="handleAddAn">确定 </a-button>
                  </div> -->
                </div>
                <div class="codeWxName" v-if="tabIndex == 1">
                  <iframe
                    id="iframe"
                    :src="NeedWx"
                    width="300px"
                    height="400px"
                    frameborder="0"
                    name="iframea"
                    sandbox="allow-scripts allow-top-navigation allow-same-origin"
                  ></iframe>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="item" v-if="tabKey === 3">
            <a-table
              :columns="columnsM"
              :data-source="infoCode"
              :pagination="false"
              :row-key="(record) => record.id"
              :scroll="{ x: 1050 }"
            >
              <template slot="avatar" slot-scope="record">
                <div class="avatarUrl">
                  <img class="img" :src="record.avatar" alt="avatar" />
                </div>
              </template>
              <template slot="spreadRate" slot-scope="record">
                <span
                  >{{ parseFloat(record.spreadRate * 100).toFixed(0) }}%</span
                >
              </template>
              <template slot="action" slot-scope="record">
                <a-button type="link" @click="assignRate(record)">
                  分配旗下业务员比例
                </a-button>
              </template>
            </a-table>
          </div>
          <div class="item" v-if="tabKey === 4">
            <a-table
              :columns="columnsMaster"
              :data-source="infList"
              :pagination="false"
              :row-key="(record) => record.id"
              :scroll="{ x: 1050 }"
            >
              <template slot="avatar" slot-scope="record">
                <div class="avatarUrl">
                  <img class="img" :src="record.avatar" alt="avatar" />
                </div>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      title="余额提现"
      :visible="modalVisible"
      :footer="false"
      :width="1044"
      @cancel="handleModalCancel"
    >
      <div class="filter">
        <div class="date">
          <span>选择业绩时间段：</span>
          <a-range-picker
            :ranges="{
              今天: [moment(), moment()],
              '7天': [moment().subtract(6, 'days'), moment()],
              '30天': [moment().subtract(29, 'days'), moment()]
            }"
            v-model="dateValue"
            @change="onTimeChange"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </div>
        <div class="btns">
          <a-button
            type="primary"
            class="modal-btn-1"
            :loading="filterLoading"
            @click="filterTap"
            :disabled="!filterDate.length"
          >
            查询业绩
          </a-button>
          <a-button
            class="modal-btn-2"
            @click="resetTap"
            :disabled="!filterDate.length"
          >
            重置时间
          </a-button>
        </div>
      </div>
      <template v-if="isFilter">
        <a-alert
          :message="
            '（' + filterDate[0] + '至' + filterDate[1] + '）时间段没有业绩喔'
          "
          type="warning"
          show-icon
          v-if="dataSource2.length === 0"
        />
        <a-alert
          :message="
            '（' +
            filterDate[0] +
            '至' +
            filterDate[1] +
            '）时间段共' +
            pagination2.total +
            '张业绩，共计' +
            sumPrice +
            '元'
          "
          type="success"
          show-icon
          v-else
        />
        <a-table
          v-if="dataSource2.length > 0"
          :columns="columns2"
          :data-source="dataSource2"
          :pagination="pagination2"
          :row-key="(record) => record.id"
          :loading="loading2"
          @change="handlePaginationChange2"
        >
          <template slot="price" slot-scope="records">
            <a-statistic prefix="￥" :value="records.price" />
          </template>
        </a-table>

        <div class="footer-btns" v-if="dataSource2.length > 0">
          <a-button
            class="footer-btn-1"
            :loading="filterLoading"
            @click="handleModalCancel"
            :disabled="!filterDate.length"
          >
            取 消
          </a-button>
          <a-button
            type="primary"
            class="footer-btn-2"
            @click="initiateWithdraw"
            :loading="submitLoading"
            :disabled="!filterDate.length"
          >
            提交提现
          </a-button>
        </div>
      </template>
    </a-modal>
    <a-modal v-model="modalVisibleCode" title="分享微有奖" :footer="false">
      <div class="codeWx"><img :src="urlCode" /></div>
    </a-modal>
    <a-modal v-model="modalIndex" title="分享微信" :footer="false">
      <div class="codeWxName">
        <iframe
          id="iframe"
          :src="wechatLink"
          width="300px"
          height="400px"
          frameborder="0"
          name="iframea"
          sandbox="allow-scripts allow-top-navigation allow-same-origin"
        ></iframe>
      </div>
    </a-modal>
    <!-- 绑定业务分配比例 -->
    <a-modal
      title="分配业务员比例"
      :visible="assignRateShow"
      @cancel="handleModalCancelAss"
    >
      <a-form-model
        ref="ruleForm"
        :model="formTion"
        :rules="rulesTion"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item
          ref="spreadRate"
          label="分配业务员比例："
          prop="spreadRate"
        >
          <a-input
            placeholder="请输入分配业务员比例"
            v-model="formTion.spreadRate"
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="handleModalCancel"> 取消 </a-button>
        <a-button type="primary" @click="sumbitFowll"> 确定 </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      info: null,
      balance: 0,
      tabKey: 0,
      assignRateShow: false,
      tabIndex: 0,
      tabs: [{ name: '表格输入' }, { name: '扫码邀请' }],
      formTion: {
        id: '',
        spreadRate: ''
      },
      rulesTion: {
        spreadRate: [
          { required: true, message: '请输入分配业务员比例', trigger: 'blur' }
        ]
      },
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '业绩类型',
          dataIndex: 'remark',
          width: 150
        },
        {
          title: '业绩金额',
          width: 100,
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '业绩下发时间',
          width: 200,
          dataIndex: 'createAt'
        },
        {
          title: '业绩流水号',
          width: 200,
          dataIndex: 'outTradeNo'
        },
        {
          title: '订单号',
          dataIndex: 'orderNum'
        }
      ],
      dataSource: [],
      loading: true,
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 4
      },
      modalVisible: false,
      columns2: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '业绩类型',
          dataIndex: 'remark',
          width: 150
        },
        {
          title: '业绩金额',
          width: 100,
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '业绩下发时间',
          width: 200,
          dataIndex: 'createAt'
        },
        {
          title: '业绩流水号',
          width: 200,
          dataIndex: 'outTradeNo'
        },
        {
          title: '订单号',
          dataIndex: 'orderNum'
        }
      ],
      dataSource2: [],
      loading2: true,
      pagination2: {
        current: 1,
        total: 0,
        defaultPageSize: 4
      },
      dateValue: [],
      filterLoading: false,
      isFilter: false,
      filterDate: [],
      sumPrice: 0,
      submitLoading: false,
      userType: '',
      modalVisibleCode: false,
      urlCode: '',
      infList: [],
      infoCode: [],
      columnsM: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '昵称',
          width: 100,
          dataIndex: 'nickname'
        },
        {
          title: '微信头像',
          width: 100,
          scopedSlots: {
            customRender: 'avatar'
          }
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
          width: 200
        },
        {
          title: '比例',
          width: 100,
          scopedSlots: {
            customRender: 'spreadRate'
          }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      columnsMaster: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '真实姓名',
          width: 100,
          dataIndex: 'realName'
        },
        {
          title: '微信头像',
          width: 100,
          scopedSlots: {
            customRender: 'avatar'
          }
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
          width: 200
        }
      ],
      modalIndex: false,
      wechatLink: '',
      NeedWx: '',
      AnnualAssessmentData: [
        {
          name: '',
          mobile: '',
          desc: '',
          rate: ''
        }
      ],
      colum: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          align: 'center',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '真实姓名',
          align: 'center',
          width: 250,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '手机号码',
          align: 'center',
          scopedSlots: { customRender: 'mobile' },
          width: 250
        },
        {
          title: '备注',
          align: 'center',
          scopedSlots: { customRender: 'desc' },
          width: 250
        },
        {
          title: '比例',
          align: 'center',
          scopedSlots: { customRender: 'rate' },
          width: 250
        },
        {
          title: '操作',
          fixed: 'right',
          align: 'center',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getPersonalInfo()
    this.userType = window.sessionStorage.getItem('userType')
    const query = this.$route.query
    console.log('query', query)
    if (query.code) {
      // 绑定微信
      // this.stepsCurrent = 1
      const params = {
        code: query.code,
        userType: query.userType,
        id: query.id
      }
      if (!query.spread) {
        this.getBecomeCommunitySpread(params)
      } else {
        this.channelBusinessBindWX(params)
      }
    }
  },
  methods: {
    moment,
    getPersonalInfo() {
      // 获取个人中心信息
      this.$axios.getPersonalInfo().then((res) => {
        const d = res.data.data
        this.info = d
        this.subTitle = d.username
        // this.getPersonalWallet()
        // this.getCommunityServicer()
        // this.getCommunitySpread()
        // this.getNeedBecomecompany()
      })
    },
    tabKeyClick(index) {
      this.tabKey = index
      if (index == 0) {
        this.getPersonalInfo()
      }
      if (index == 1) {
        this.getPersonalWallet()
      }
      if (index == 2) {
        this.getNeedBecomecompany()
      }
      if (index == 3) {
        this.getCommunitySpread()
      }
    },
    getCommunityServicer() {
      if (this.info.id) {
        const data = {
          id: this.info.id
        }
        console.log('getCommunityServicer', this.info)
        this.$axios.getCommunityServicer(data).then((res) => {
          this.infList = res.data.data.list
        })
      }
    },
    getCommunitySpread() {
      if (this.info.id) {
        const data = {
          id: this.info.id
        }
        this.$axios.getCommunitySpread(data).then((res) => {
          this.infoCode = res.data.data.list
          console.log('fdfd', this.infoCode)
        })
      }
    },
    getNeedBecomecompany() {
      if (this.info.id) {
        const data = {
          id: this.info.id
        }
        console.log('getCommunityServicer', this.info)
        this.$axios.getNeedBecomecompany(data).then((res) => {
          this.NeedWx = res.data.data.url
          console.log('getCommunityServicer', res.data.data)
        })
      }
    },
    getPersonalWallet() {
      // 获取个人中心钱包余额
      this.$axios.getPersonalWallet().then((res) => {
        this.balance = res.data.data
        this.getPersonalWalletFlow()
      })
    },
    getPersonalWalletFlow() {
      // 获取个人钱包流水
      const data = {
        page: this.pagination.current,
        limit: 4
      }
      this.$axios.getPersonalWalletFlow(data).then((res) => {
        const d = res.data.data
        this.dataSource = d.data
        this.pagination.total = d.count
        this.loading = false
      })
    },
    handlePaginationChange(e) {
      this.loading = true
      this.pagination.current = e.current
      this.getPersonalWalletFlow()
    },
    handlePaginationChange2(e) {
      this.loading2 = true
      this.pagination2.current = e.current
      this.getPerformance()
    },
    onWithdrawOpen() {
      // 显示提现弹窗
      this.$axios
        .checkBindWX({}, { hideMessage: true })
        .then(() => {
          // console.log(res)
          this.modalVisible = true
        })
        .catch((err) => {
          this.$warning({
            icon: () => <a-icon theme="filled" type="exclamation-circle" />,
            title: '很抱歉，余额提现被阻止',
            content: err.msg
          })
        })
    },
    handleModalCancel() {
      this.modalVisible = false
      this.resetTap()
    },
    onTimeChange(date, dateString) {
      // 时间选择
      this.filterDate = dateString
    },
    filterTap() {
      // 查询业绩
      this.filterLoading = true
      this.getPerformance()
    },
    resetTap() {
      // 重置
      this.filterDate = []
      this.dataSource2 = []
      this.loading2 = true
      this.pagination2 = {
        current: 1,
        total: 0,
        defaultPageSize: 4
      }
      this.dateValue = []
      this.filterLoading = false
      this.isFilter = false
      this.submitLoading = false
    },
    getPerformance() {
      // 获取业绩列表
      const data = {
        page: this.pagination2.current,
        limit: 4,
        start: this.filterDate[0] + ' 00:00:00',
        end: this.filterDate[1] + ' 23:59:59'
      }
      this.$axios.getPerformance(data).then((res) => {
        const d = res.data.data
        this.dataSource2 = d.data
        this.pagination2.total = d.count
        this.sumPrice = d.sumPrice
        this.loading2 = false
        this.filterLoading = false
        this.isFilter = true
      })
    },
    initiateWithdraw() {
      this.submitLoading = true
      const data = {
        start: this.filterDate[0] + ' 00:00:00',
        end: this.filterDate[1] + ' 23:59:59'
      }
      this.$axios.initiateWithdraw(data).then(() => {
        this.$message.success('操作成功')
        this.handleModalCancel()
        this.submitLoading = false
        this.getPersonalInfo()
      })
    },
    // 点击获取二维码
    modalVisibleClick() {
      // console.log('data', this.info)
      this.modalVisibleCode = true
      this.urlCode = this.info.wechatAppletsQcode
    },
    switchClick() {
      console.log('切换社区账号')
      const data = {
        id: this.info.id
      }

      this.$confirm({
        title: '切换账号',
        content: '确认切换账号？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.$axios.getNeedSwtichCommunity(data).then((res) => {
            this.modalIndex = true
            this.infoIndex = res.data.data
            this.wechatLink = res.data.data.url
            console.log('切换社区账号', res.data)
          })
        }
      })
    },

    channelBusinessBindWX(data) {
      // 绑定微信
      const hide = this.$message.loading('请稍后', 0)
      this.$axios.getBindCommunityOpenid(data).then(() => {
        hide()
        this.$message.success('操作成功')
        this.modalIndex = false
        this.$router.replace({
          path: '/personalInfo'
        })
      })
    },
    getBecomeCommunitySpread(data) {
      // 绑定合伙人微信
      const hide = this.$message.loading('请稍后', 0)
      this.$axios.getBecomeCommunitySpread(data).then(() => {
        hide()
        this.$message.success('操作成功')
        this.modalIndex = false
        this.$router.replace({
          path: '/personalInfo'
        })
      })
    },
    assignRate(record) {
      this.assignRateShow = true
      this.formTion.id = record.id
    },
    handleModalCancelAss() {
      this.assignRateShow = false
      this.$refs.ruleForm.resetFields()
    },
    sumbitFowll() {
      // if (!/(^[1-9]\d*$)/.test(this.formTion.spreadRate)) {
      //   this.$message.error('请输入正整数0也不能输入')
      //   return false
      // }
      if (this.formTion.spreadRate < 100) {
        this.formTion.spreadRate = this.formTion.spreadRate / 100
      } else {
        this.$message.error('比例不能超出100')
        return
      }
      this.$axios.getAssignRate(this.formTion).then(() => {
        this.$message.success('操作成功')
        this.getCommunitySpread()
        this.assignRateShow = false
      })
    },
    // 切换二维码
    callback(key) {
      // tab切换
      this.tabIndex = key
    },
    //增加年度考核行
    handleAddAnnualAssessmentLine() {
      if (this.AnnualAssessmentData == undefined) {
        this.AnnualAssessmentData = new Array()
      }
      let obj = {}

      this.AnnualAssessmentData.push(obj)
    },
    // //保存年度考核行
    // handlesaveAnnualAssessment(a, b) {

    //   console.log(a + b)
    // },
    //删除年度考核行
    handleDeleteAnnualAssessment(index) {
      console.log(index)
      if (this.AnnualAssessmentData.length == 1) {
        this.$message.error('首行不能删除')
      } else {
        this.AnnualAssessmentData.splice(index, 1)
      }
    },
    // 确定
    handleAddAn() {
      // this.formTion.spreadRate = this.formTion.spreadRate / 100

      this.AnnualAssessmentData.forEach((element) => {
        if (element.rate <= 100) {
          element.rate = element.rate / 100
        } else {
          this.$message.error('比例不能超过100')
          return
        }
      })
      this.$axios
        .getApplyBussiness({ userInfo: this.AnnualAssessmentData })
        .then(() => {
          this.$message.success('操作成功')
          this.AnnualAssessmentData = [
            {
              name: '',
              mobile: '',
              desc: '',
              rate: ''
            }
          ]
        })
    }
  }
}
</script>

<style scoped>
.conteiner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

.body {
  height: 100%;
  display: flex;
  background-color: #fff;
  padding: 20px 0;
}

.bodyt {
  background-color: #fff;
  padding: 48px 0;
}

.form {
  width: 450px;
  margin: 0 auto;
}

.submit-btn {
  margin-left: 9.5em;
}

.body .left {
  flex: 0 0 190px;
  padding: 16px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.body .left .item {
  height: 46px;
  line-height: 46px;
  padding: 0 32px;
  position: relative;
}

.body .left .item:not(:last-child) {
  margin-bottom: 8px;
}

.body .left .item.active {
  color: #1791ff;
  background-color: #e6f7ff;
}

.body .left .item.active::after {
  content: '';
  width: 4px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #1791ff;
}

.body .right {
  flex: 1;
  padding: 16px 24px;
}

.content-item-header .title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.content-sub-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
}

.ul .li {
  margin-top: 16px;
}

.ul .li .label {
  color: rgba(0, 0, 0, 0.85);
}

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #333;
}

.title-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 32px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.title-2 .label {
  color: #333;
}

.title-2 >>> .a-statistic {
  margin-left: 8px;
}

.title-2 >>> .a-statistic .ant-statistic-content-value-int,
.title-2 >>> .a-statistic .ant-statistic-content-value-decimal {
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  color: #333;
}

.title-2 .btn-2 {
  margin-left: 8px;
}

.title-3 {
  font-weight: bold;
  color: #333;
  margin: 24px 0 16px 0;
}

.filter {
  display: flex;
}

.ant-calendar-picker {
  width: 260px;
}

.modal-btn-1 {
  margin: 0 8px 0 16px;
}

.ant-alert {
  margin: 16px 0;
}

.footer-btns {
  position: absolute;
  left: 32px;
  bottom: 34px;
}

.footer-btn-2 {
  margin-left: 8px;
}
.shareCode {
  position: absolute;
  top: 5%;
  right: 5%;
}
.codeWxName {
  width: 300px;
  height: 400px;
  margin: 0 auto;
  margin-top: 20px;
}
.codeWx {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  /* border: 1px solid red; */
}
.codeWx img {
  width: 100%;
  height: 100%;
}
.avatarUrl {
  width: 50px;
  height: 50px;
}
</style>
